// src/components/ResourceHero.js

import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

function ResourceHero(props) {
  const heroImage = props.heroImage || null

  const hero = function () {
    if (heroImage) {
      const image = getImage(heroImage.localFile)
      return (
        <GatsbyImage
          image={image}
          alt={heroImage.altText}
          className="h-auto w-full"
        />
      )
    } else {
      return null
    }
  }

  return <>{hero()}</>
}

export default ResourceHero
